import { useEffect, useRef, useState } from 'react'
// import useSound from 'use-sound'
import ReactGA from 'react-ga4'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

// @ts-ignore
// import intro from './intro/intro.mp3'
const INTRO_AUDIO_URL = '/audio/intro_i3.mp3'

export const Hero = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation('landing')

  /*
  // const [playsound, { stop }] = useSound(INTRO_AUDIO_URL)
  const audioRef = useRef<HTMLAudioElement | null>(null)
  const [playing, setPlaying] = useState(false)

  useEffect(() => {
    audioRef.current = new Audio(INTRO_AUDIO_URL)
    audioRef.current.addEventListener('ended', () => setPlaying(false))
    return () => {
      audioRef.current?.removeEventListener('ended', () => setPlaying(false))
    }
  }, [])

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause()
        setPlaying(false)
      }
    }
  }, [location])

  const togglePlay = () => {
    if (!audioRef.current) return

    if (playing) {
      audioRef.current.pause()
      setPlaying(false)
    } else {
      audioRef.current.play().catch((error) => {
        console.error('Error playing audio:', error)
        setPlaying(false)
      })
      setPlaying(true)
    }
  }
*/
  const [isStuck, setIsStuck] = useState(false)
  const buttonsRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleScroll = () => {
      if (buttonsRef.current) {
        const heroRect = document.querySelector('.hero')?.getBoundingClientRect()
        const buttonRect = buttonsRef.current.getBoundingClientRect()
        if (heroRect) {
          setIsStuck(heroRect.bottom <= buttonRect.height)
        }
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <div className="hero">
      <div className="container">
        <div className="hero__content">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="175"
            height="31"
            viewBox="0 0 175 31"
            fill="none"
          >
            <path
              d="M53.5089 8.525H56.1032L51.558 23.25H48.9637L45.6431 12.2694L42.3224 23.25H39.7281L35.2036 8.525H37.7772L41.0771 20.4312L44.6053 8.525H46.7015L50.2298 20.4312L53.5089 8.525Z"
              fill="#d58010"
            />
            <path
              d="M56.1322 18.2014C56.1322 14.7095 58.7473 12.8794 61.5284 12.8794C64.3302 12.8794 66.9245 14.7095 66.9245 18.2014C66.9245 21.6934 64.3302 23.5235 61.5284 23.5235C58.7473 23.5235 56.1322 21.6934 56.1322 18.2014ZM64.6623 18.2014C64.6623 16.2241 63.3755 14.9409 61.5284 14.9409C59.6605 14.9409 58.3737 16.2241 58.3737 18.2014C58.3737 20.1788 59.6605 21.462 61.5284 21.462C63.3755 21.462 64.6623 20.1788 64.6623 18.2014Z"
              fill="#d58010"
            />
            <path
              d="M71.0494 14.8147C71.8173 13.5736 73.4361 12.7742 75.2418 12.9425V15.1512C73.4569 14.8988 71.9003 15.6982 71.2984 17.1286V23.25H69.0777V13.1529H71.0494V14.8147Z"
              fill="#d58010"
            />
            <path d="M76.9629 7.89393H79.1836V23.25H76.9629V7.89393Z" fill="#d58010" />
            <path
              d="M89.916 8.525H92.1367V23.25H90.1443V21.6513C89.3764 22.64 88.1934 23.5235 86.3255 23.5235C83.3161 23.5235 81.386 21.1044 81.386 18.2014C81.386 15.2985 83.3161 12.8794 86.3255 12.8794C88.0689 12.8794 89.2311 13.6577 89.916 14.4571V8.525ZM89.8953 16.9603C89.4179 15.8665 88.2764 14.8567 86.7198 14.8567C84.935 14.8567 83.6275 16.2241 83.6275 18.2014C83.6275 20.1788 84.935 21.5461 86.7198 21.5461C88.2764 21.5461 89.4179 20.5364 89.8953 19.4215V16.9603Z"
              fill="#d58010"
            />
            <path
              d="M108.472 8.525H110.609V23.25H108.244V12.9425L103.657 19.9264H102.246L97.6588 12.9215V23.25H95.2928V8.525H97.4305L102.951 16.9603L108.472 8.525Z"
              fill="#d58010"
            />
            <path
              d="M120.991 13.1529H123.212V23.25H121.24V21.8616C120.514 22.7451 119.289 23.5235 117.774 23.5235C115.138 23.5235 113.665 21.7985 113.665 19.4005V13.1529H115.885V19.106C115.885 20.7467 116.736 21.5251 118.21 21.5251C119.476 21.5251 120.43 20.6626 120.991 19.8422V13.1529Z"
              fill="#d58010"
            />
            <path
              d="M125.471 15.8875C125.471 13.6787 127.463 12.8794 129.435 12.8794C130.805 12.8794 132.05 13.237 133.026 13.7419V15.7192C132.195 15.1512 130.763 14.7305 129.58 14.7305C128.688 14.7305 127.733 15.004 127.733 15.8244C127.733 17.7807 133.731 16.8762 133.731 20.5574C133.731 22.7872 131.697 23.5235 129.435 23.5235C127.982 23.5235 126.509 23.1659 125.533 22.5558V20.5574C126.571 21.2516 128.086 21.6302 129.331 21.6302C130.577 21.6302 131.49 21.4199 131.49 20.5995C131.49 18.7273 125.471 19.5056 125.471 15.8875Z"
              fill="#d58010"
            />
            <path
              d="M135.116 18.2014C135.116 14.5412 137.856 12.8794 140.159 12.8794C144.123 12.8794 145.493 16.1189 144.85 19.0429H137.358C137.607 20.8519 139.35 21.6092 141.176 21.6092C142.38 21.6092 143.48 21.3147 144.331 20.894V22.8293C143.418 23.271 142.152 23.5235 140.761 23.5235C137.71 23.5235 135.116 21.7565 135.116 18.2014ZM142.857 17.36C142.982 16.4765 142.442 14.7516 140.263 14.7516C138.769 14.7516 137.42 15.6561 137.337 17.36H142.857Z"
              fill="#d58010"
            />
            <path
              d="M154.331 13.1529H156.552V23.25H154.581V21.8616C153.854 22.7451 152.63 23.5235 151.115 23.5235C148.479 23.5235 147.005 21.7985 147.005 19.4005V13.1529H149.226V19.106C149.226 20.7467 150.077 21.5251 151.55 21.5251C152.816 21.5251 153.771 20.6626 154.331 19.8422V13.1529Z"
              fill="#d58010"
            />
            <path
              d="M161.551 23.25H159.331V13.1529H161.302V14.415C161.842 13.805 163.004 12.8794 164.623 12.8794C166.117 12.8794 167.155 13.5105 167.736 14.4991C168.608 13.6156 169.77 12.8794 171.223 12.8794C173.859 12.8794 175 14.8567 175 16.9393V23.25H172.779V17.0024C172.779 15.6982 172.011 14.8778 170.808 14.8778C169.708 14.8778 168.857 15.614 168.338 16.2451C168.359 16.4765 168.379 16.7079 168.379 16.9393V23.25H166.159V17.0024C166.159 15.6982 165.391 14.8778 164.187 14.8778C162.983 14.8778 162.07 15.7613 161.551 16.4344V23.25Z"
              fill="#d58010"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.006 31L13.9565 30.2026C21.9493 23.4972 26.012 17.6423 26.012 12.4958C26.012 5.20749 20.0913 0 13.006 0C5.92069 0 0 5.20749 0 12.4958C0 17.6423 4.06265 23.4972 12.0555 30.2026L13.006 31ZM13.006 28.147C5.60695 21.9809 1.97061 16.7559 1.97061 12.6024C1.97061 6.62068 6.95879 1.60618 13.006 1.60618C19.0532 1.60618 24.0414 6.62068 24.0414 12.6024C24.0414 16.7559 20.405 21.9809 13.006 28.147Z"
              fill="#d58010"
            />
            <path
              d="M6.92334 15.8875C6.92334 17.3148 8.06854 18.4062 9.5661 18.4062H10.447V13.3687H9.5661C8.94946 13.3687 8.42091 13.5367 7.98045 13.8725C7.09953 10.5981 9.6542 7.49167 13.0898 7.49167C16.5254 7.49167 19.08 10.5981 18.1991 13.8725C17.7587 13.5367 17.2301 13.3687 16.6135 13.3687H15.7325V18.4062H16.6135C18.111 18.4062 19.2562 17.3148 19.2562 15.8875V15.8035C21.899 11.2698 18.4634 5.8125 13.0898 5.8125C7.71617 5.8125 4.36867 11.2698 6.92334 15.8035V15.8875Z"
              fill="#d58010"
            />
          </svg>
          <div className="hero__title">
            <img src="./img/ALL ROME.png" alt="" />
          </div>
          <div className="hero__info-top">
            <p>
              <span>450+</span>
              <br />
              {t('audioPreview.count')}
            </p>
            {/* <button onClick={togglePlay}>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                <path
                  fill="#132648"
                  fillRule="evenodd"
                  d="M18.97 6.97a.75.75 0 0 1 1.06 0l-.53.53l.53-.53h.001l.001.002l.003.002l.007.007l.02.02l.062.069c.05.057.12.138.201.241A6.87 6.87 0 0 1 21.75 11.5a6.87 6.87 0 0 1-1.425 4.189a5 5 0 0 1-.264.31l-.02.02l-.006.007l-.003.002v.001h-.001l-.51-.508l.51.51a.75.75 0 1 1-1.061-1.061l.53.53l-.53-.53h-.001v.001l-.002.001l.005-.005l.033-.036q.048-.052.139-.167a5.37 5.37 0 0 0 .448-5.843a5 5 0 0 0-.448-.685a3 3 0 0 0-.172-.203l-.005-.005a.75.75 0 0 1 .003-1.058m-5.933-3.574c1.163-.767 2.713.068 2.713 1.461v14.286c0 1.394-1.55 2.228-2.713 1.461l-6-3.955a.25.25 0 0 0-.137-.042H4a2.75 2.75 0 0 1-2.75-2.75v-3.714A2.75 2.75 0 0 1 4 7.393h2.9a.25.25 0 0 0 .138-.041z"
                  clipRule="evenodd"
                />
              </svg>
              {playing ? t('audioPreview.pause') : t('audioPreview.play')}
            </button> */}
          </div>
          <div className="hero__info">
            <p>
              {t('title.first')}
              <br />
              {t('title.second')}
              <br />
              {t('title.third')}
              <br />
              {t('title.fourth')}
            </p>
          </div>

          <div className="hero__content__overlay">
            <p style={{ fontSize: '24px', color: 'red' }}>€ 0</p>
            <p>{t('overlayText')}</p>
          </div>

          {/* New buttons container */}
          <div ref={buttonsRef} className={`hero__buttons-container ${isStuck ? 'stuck' : ''}`}>
            <button
              className="map__btn"
              onClick={() => {
                navigate('/map')
                ReactGA.event({
                  category: 'click',
                  action: 'ClickedMap',
                })
              }}
            >
              {t('toMap')}
            </button>
            <button
              className="route__btn"
              onClick={() => {
                navigate('/map?startExcursion')
                ReactGA.event({
                  category: 'click',
                  action: 'ClickedExcursions',
                })
              }}
            >
              {t('route')}
            </button>
          </div>

          <img className={'bg'} src="./img/bgss.webp" alt="" />
        </div>
      </div>
    </div>
  )
}
